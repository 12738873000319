<template>
    <div id="research">
        <div class="left">
            <div class="post-bar_wrapper">
                <div class="title">
                    <span>碳问热帖</span>
                </div>
                <div class="posts">
                    <div
                        :class="['item', item.parent_post_article && item.parent_post_article.id ? 'has-parent' : '', currentHoverId == item.id ? 'is-hover' : '']"
                        v-for="item in posts" :key="item.id" @click="handleView(item)" @mouseenter="handleMouseEnter(item.id)" @mouseleave="handleMouseLeave"
                    >
                        <div class="title" v-html="item.title">
                            <!-- {{ item.title }} -->
                        </div>
                        <div class="content" v-html="item.content">
                            <!-- {{ item.content }} -->
                        </div>
                        <div
                            v-if="item.parent_post_article && item.parent_post_article.id"
                            :class="['parent-post', item.id == currentHoverId && item.parent_post_article.id == currentHoverParentId ? 'is-hover' : '']"
                            @click.stop="handleView(item.parent_post_article)"
                            @mouseenter="handleMouseParentEnter(item.id, item.parent_post_article.id)" @mouseleave="handleMouseParentLeave"
                        >
                            <div class="title" v-html="item.parent_post_article.title"></div>
                            <div class="content" v-html="item.parent_post_article.content"></div>
                            <div class="source">
                            <!-- <img src="../../assets/images/company2.png"> -->
                            <UserLogoComponent
                                width="24" height="24"
                                :user="{
                                    avatar: item.parent_post_article.user_info_avatar,
                                    id: item.parent_post_article.user_info_id,
                                }"
                            />
                            <span class="user-name">{{ item.parent_post_article.user_info_name || '&nbsp;' }}</span>
                                <span class="time">{{ item.parent_post_article.create_time }}</span>
                            </div>
                        </div>
                        <div class="source">
                            <!-- <img src="../../assets/images/company2.png" @click.stop="handleMine(item)"> -->
                            <UserLogoComponent
                                width="24" height="24"
                                :user="item.user_info"
                            />
                            <span class="user-name">{{ item.user_info ? item.user_info.nickname || '&nbsp;' : '&nbsp;' }}</span>
                            <span class="time">{{ item.create_time }}</span>
                        </div>
                    </div>
                    <div v-if="empty" class="empty">暂无结果</div>
                </div>
                <div :class="['more', { 'loading': loading }]" v-loading="loading">
                    <span v-show="scrollCount > 2 && more" @click="handleMore">加载更多</span>
                </div>
            </div>
        </div>
        <div class="right"></div>
    </div>
</template>

<script>
    import UserLogoComponent from '../../components/UserLogo/index.vue';
    export default {
        name: 'Research',
        components: {
            UserLogoComponent,
        },
        data(){
            return {
                pagination: {
                    page: 1,
                    limit: 20,
                    page_count: 0,
                },
                keyword: '',
                posts: [],
                currentHoverId: '',
                currentHoverParentId: '',
                more: true,
                scrollCount: 0,
                loading: false,
                empty: false,
            }
        },
        activated () {
            console.log('activated, Research');
            this.loading = false;
            this.more = true;
            this.scrollCount = 0;
            this.getPostList();
            window.addEventListener("scroll", this.handleScroll);
        },
        methods: {
            getPostList(){
                // /api/article/getpostarticlelist
                console.log('getPostList');
                this.api.postFormAPISM(
                    {
                        ...this.pagination,
                        keywords: this.keyword || "",
                        start_time: '',
                    },
                    "/article/getpostarticlelist"
                ).then((res) => {
                    console.log('getPostList, then', res.data);
                    this.posts = res.data.data.article_info_list;
                    
                }).catch((error) => {
                    console.log("error");
                });
            },
            replaceImage(content, id, index, title) {
                let reg = /({{[^}}]+.(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|avif|apng)}})/g;
                let reg2 = /^{{([^}}]+)}}$/;
                let length = 300;
                // console.log('replaceImage');
                let temp = content.replace(/<br>|\s/g, "");
                let keyword = this.$route.query.keyword;
                if (keyword){
                    //  \u4e00-\u9fa5
                    // new RegExp(`[,.，。!]*([^,.，。]{5,200}${ keyword }.{150,200})`);
                    let reg = new RegExp(`([^,.，。]{0,50}${ keyword }.{0,250})`);
                    // if (!index){
                    //     console.log('reg', reg);
                    //     console.log(reg.exec(temp));
                    // }
                    let outcome = reg.exec(temp);
                    if (outcome instanceof Array && outcome[1]){
                        temp = outcome[1].replaceAll(keyword, `<span>${ keyword }</span>`);
                    }
                    // if (title.includes('江苏省生态环境厅')){
                    //     console.log('reg', reg);
                    //     console.log('outcome', outcome);
                    // }
                    // let firstIndex = temp.indexOf(keyword);
                    // console.log('firstIndex', firstIndex);
                    // let target = '';
                    // let sliceString = (temp, startIndex) => {
                    //     // let string = temp;
                        
                    //     if (startIndex - 1 === 0){
                    //         target += temp[0]
                    //     }
                    // }
                }
                // console.log('replaceImage', content.match(/<br>/g));
                let tempImage = temp.match(reg) || [];
                tempImage.length && console.log("tempImage", id);
                for (let i = 0; i < tempImage.length; i++) {
                    let url = reg2.exec(tempImage[i]);
                    if (url instanceof Array) {
                        url = url[1];
                        temp = temp.replace(tempImage[i], "");
                        // temp = temp.replace(tempImage[i], imageTemplate(ImageBasicUrl + url));
                    }
                }
                // function getSliceStr(str){
                //     if ( str.length < length || (/[\u4e00-\u9fa5]/.test(str[length]) && /[\u4e00-\u9fa5]/.test(str[length-1]))){
                //         return str.slice(0, length) + (!(str.length < length) ? '....' : '');
                //     } else {
                //         length++;
                //         return getSliceStr(str);
                //     }
                // }
                // temp = getSliceStr(temp);
                // console.log('replaceImage, length', length, temp.length);
                return temp;
            },
            handleMore(text) {
                console.log("handleMore", text, this.scrollCount);
                this.pagination.page++;
                let keyword = this.$route.query.keyword;
                // this.pagination.limit += 20;
                this.api.postFormAPISM(
                    {
                        ...this.pagination,
                        keywords: keyword || "",
                        start_time: this.posts[this.posts.length - 1].create_time,
                    },
                    "/article/getpostarticlelist"
                ).then((res) => {
                    this.posts = [
                        ...this.posts,
                        ...res.data.data.article_info_list.map((item) => {
                            return {
                                ...item,
                                title: item.title.replace(keyword, `<span>${ keyword }</span>`),
                                content: this.replaceImage(item.content, item.id, item.title),
                            };
                        }),
                    ];
                    // console.log('handleMore', this.posts);
                    //   this.pagination.page_count = res.data.data.pagination.page_count;
                    this.more = res.data.data.article_info_list.length === this.pagination.limit;
                    if (text) {
                        this.loading = false;
                        this.scrollCount++;
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
            },
            handleMouseEnter(currentHoverId){
                this.currentHoverId = currentHoverId;
            },
            handleMouseParentEnter(currentHoverId, currentHoverParentId){
                this.currentHoverId = currentHoverId;
                this.currentHoverParentId = currentHoverParentId;
            },
            handleMouseLeave(){
                this.currentHoverId = '';
                this.currentHoverParentId = '';
            },
            handleMouseParentLeave(){
                this.currentHoverParentId = '';
            },
            handleView({ title, id }) {
                // this.$router.push(
                //     {
                //         path: '/news',
                //         query: {
                //             page: page + 1,
                //         }
                //     }
                // );
                let text = this.$router.resolve({
                    path: "/post",
                    query: {
                        id,
                    },
                });
                // console.log("text", text.href);
                // 打开一个新的页面
                window.open(text.href, "_blank");
            },
            handleMine(item){
                console.log('handleMine', item);
                let { user_info: { id } } = item;
                console.log('user_info', id);
                let route = this.$router.resolve({
                    path: `/u/${ id }`,
                    // query: {
                    //     id: encodeURIComponent(encodeURIComponent(id))
                    // }
                });
                window.open(route.href, '_blank');
            },
            handleScroll() {
                if (!this.posts.length || this.loading || this.scrollCount > 2) return;
                // 距顶部
                var scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                // 可视区高度
                var clientHeight =
                    document.documentElement.clientHeight || document.body.clientHeight;
                // 滚动条总高度
                var scrollHeight =
                    document.documentElement.scrollHeight || document.body.scrollHeight;
                let targetHeight = scrollHeight - 257 - 140;
                // console.log(scrollTop, clientHeight, scrollHeight, targetHeight,  targetHeight - scrollTop - clientHeight);
                if (targetHeight - scrollTop - clientHeight < 10) {
                    this.loading = true;
                    // return
                    setTimeout(() => {
                        this.handleMore("scroll");
                    }, 500);
                }
            }
        }
    }
</script>
<style lang="less">
    @import './index.less';
</style>